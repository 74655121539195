import React from 'react';
import Logo from "./biopama_logo.svg";
import Status from './Status.js';
import TooltipSlider from './components/TooltipSlider.tsx';
import StatsBar from './StatsBar.js';
import SyncIcon from '@mui/icons-material/Sync';
import ZoomOutMap from '@mui/icons-material/Public';
import TimelineIcon from '@mui/icons-material/Timeline';
import IconButton from '@mui/material/IconButton';


class AppBar extends React.Component {

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('shouldComponentUpdate lifecycle');
  //   return false;
  // }

  async zoomOutMap() {
    await this.props.zoomOutMap();
  }
  showTrends(){
    this.props.showTrends();
  }
  onBeforeChange(values) {
    this.props.onBeforeChange(values);
  }
  onChange(values) {
    this.props.onChange(values, this.marks);
  }
  render() {
    if (this.props.versions === undefined) return null;
    //get the width of the slider depending on how many versions of the WDPA we have
    let divisions = this.props.versions.length -1; //an extra division at the end
    //let width = (divisions * 60); //60px between each mark
    //get the marks for the slider
    this.marks = {};
    const markLabels = [];
    this.props.versions.forEach(version => {
      this.marks[version.id] = version.shortTitle;
      markLabels.push(version.shortTitle);
    });
    let isMonthlyChange = ((this.props.toVersion&&this.props.toVersion.id) - (this.props.fromVersion&&this.props.fromVersion.id)) === 1;
    let wcmcChangeLogUrl = 'https://www.protectedplanet.net/en/resources/' + (this.props.toVersion&&this.props.toVersion.title.toLowerCase().replace(" ","-")) + '-update-of-the-wdpa';
    let wcmcChangeLogUrl2 = 'https://www.protectedplanet.net/en/resources/' + (this.props.toVersion&&this.props.toVersion.title.toLowerCase().replace(" ","-")) + '-update-of-the-wdpa-and-wd-oecm';
    let homepageURL = 'https://rris.biopama.org';
    return (
      <React.Fragment>
          <div className={'appBar'}>
              <div>
                  <div>
                    <div className={'logoImg'}><a href={homepageURL} rel="noopener noreferrer" title='BIOPAMA RIS'><img src={Logo} alt="BIOPAMA Logo" /></a></div>
                    <div className={'appBarTitle noselect'}>WDPA Version Tracker</div>
                    <div className={'softwareVersion'}>{this.props.softwareVersion}</div>
                  </div>
                  <div className={'appBarContent noselect'}>
                      <div className={"sliderContainer"}>
                          <div style={{ width: '100%', margin: 'auto' }}>
                           <TooltipSlider 
                              range
                              keyboard={true}
                              draggableTrack={true}
                              allowCross={false}
                              max={divisions}
                              marks={this.marks} 
                              ariaValueTextFormatterForHandle={(value) => `${markLabels[value]}`}
                              step={null}
                              value={this.props.values}
                              onBeforeChange={this.onBeforeChange.bind(this)}
                              onChange={this.onChange.bind(this)}
                              tipFormatter={(value) => `${value}`}
                          />
                          </div>
                      </div>
                      <div className={'statsHolder'}>
                        <span className={'zoomOutHolder'}>
                          <ZoomOutMap titleAccess={"Return to full extent"} className={'ZoomOutMap'} onClick={this.zoomOutMap.bind(this)}/>
                        </span>
                        <span className={'countryName'} style={{display: ((this.props.view === 'global') && (this.props.gettingCountryStats === false)) ? "inline" : "none"}}>Global</span>
                        <span className={'vMiddle'} style={{display: ((this.props.values[0] === this.props.values[1]) && (this.props.view === 'global') && (this.props.gettingGlobalStats === false)) ? "inline" : "none"}}>
                          <span><Status status={'total'} amount={this.props.globalTotal}/></span>
                        </span>
                        <span className={'vMiddle'} style={{display: ((this.props.values[0] !== this.props.values[1]) && (this.props.view === 'global') && (this.props.gettingGlobalStats === false)) ? "inline" : "none"}}>
                          <StatsBar values={this.props.globalStats} showStatuses={this.props.showStatuses}/>
                        </span>
                        <span className={'countryName'} style={{display: ((this.props.view !== 'global') && (this.props.gettingCountryStats === false)) ? "inline" : "none"}}>{this.props.country && this.props.country.name}</span>
                        <span className={'vMiddle'} style={{display: ((this.props.values[0] === this.props.values[1]) && (this.props.view !== 'global') && (this.props.gettingCountryStats === false)) ? "inline" : "none"}}>
                          <Status status={'total'} amount={this.props.countryTotal}/>
                        </span>
                        <span className={'vMiddle'} style={{display: ((this.props.values[0] !== this.props.values[1]) && (this.props.view !== 'global') && (this.props.gettingCountryStats === false)) ? "inline" : "none"}}>
                          <StatsBar values={this.props.countryStats} showStatuses={this.props.showStatuses}/>
                        </span>
                        <span className={'vMiddle'}>
          	              <SyncIcon className={'spin'} style={{display: (((this.props.gettingGlobalStats)||(this.props.gettingCountryStats)) ? 'inline' : 'none'),color: 'rgb(255, 64, 129)', fontSize:'17px'}} key={"spinner"}/>
          	            </span>
          	            <span className={'wcmcChangeLog'} style={{display: isMonthlyChange ? "inline" : "none"}}>
          	              <a href={wcmcChangeLogUrl} rel="noopener noreferrer" title='WCMC WDPA change log for this month' target='_blank'>WCMC WDPA change log</a>     <a href={wcmcChangeLogUrl2} rel="noopener noreferrer" title='WCMC WDPA and WD-OECM change log for this month' target='_blank'>WCMC WDPA and WD-OECM change log</a>
          	            </span>
                        <span className={'sparklineHolder'}>
                          <IconButton aria-label="delete" size="small" onClick={this.showTrends.bind(this)} >
                            <TimelineIcon titleAccess={"View change over time"} className={'viewTrends'} style={{display: (this.props.view === 'global') ? "inline" : "none"}}/>
                          </IconButton>
                        </span>
                      </div>
                  </div>
              </div>
          </div>
      </React.Fragment>
    );
  }
}

export default AppBar;
