import React from 'react';
//import { LineChart, Line,XAxis, YAxis,Tooltip  } from 'recharts';
import ReactECharts from 'echarts-for-react';

class Trends extends React.Component {
  // shouldComponentUpdate(nextProps, nextState) {
  //   //console.log('shouldComponentUpdate lifecycle');
  //   if (this.props.global_trends.length){
  //     return true;
  //   } else{
  //     return false;
  //   }
  // }
  render() {

    const options = {
      title: {
        text: 'Global protection change over time'
      },
      //grid: { top: 20, right: 40, bottom: 20, left: 40 },
      dataset: {
        source: this.props.global_trends
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        type: "value"
      },

      series: [
        {
          type: 'line',
          showSymbol: false,
          encode: {
            // Map "amount" column to x-axis.
            x: 'shortTitle',
            // Map "product" row to y-axis.
            y: 'sum'
          }
        }
      ],
      tooltip: {
        trigger: "axis"
      }
    }

    const renderLineChart = (
      <ReactECharts 
      option={options}
      style={{ width: "1000px", height: "400px" }}
      ></ReactECharts>
    );    
    return (
      <React.Fragment>
          <div className={'trends'} style={{display:(this.props.showTrends) ? "block" : "none"}}>{renderLineChart}
          </div>
      </React.Fragment>
    );
  }
}

export default Trends;

// class Trends extends React.Component {
//   tickFormatter(value){
//     return value.toLocaleString();
//   }
//   render() {
//     const renderLineChart = (
//       <LineChart width={1000} height={200} data={this.props.global_trends}>
//         <Line type="monotone" dataKey="sum" stroke="#5b9541" />
//         <XAxis dataKey="shortTitle" data={this.props.global_trends} label={{ value: "",  angle: 0,   dy: 15}}/>
//         <YAxis type="number" domain={['auto', 'auto']} tickFormatter={this.tickFormatter.bind(this)} label={{ value: "Number of Protected Areas", angle: -90,   dx: -35}}/>
//         <Tooltip />
//       </LineChart>
//     );    
//     return (
//       <React.Fragment>
//           <div className={'trends'} style={{display:(this.props.showTrends) ? "block" : "none"}}>{renderLineChart}
//           </div>
//       </React.Fragment>
//     );
//   }
// }

// export default Trends;
