import React from 'react';
import { useTable } from 'react-table'

class Changed extends React.Component {
	renderFrom(table){
		return <div title={table.row.original.previous}>{table.row.original.previous}</div>;             
	}
	renderTo(table){
		return <div title={table.row.original.current}>{table.row.original.current}</div>;   
	}
	render() {
		//console.log(this.props);
		if (this.props.changedData === {}) return;
		let status = this.props.statuses.find(item => {return (this.props.changedData.geometryData === item.key)});
		
		const {attributesData} = this.props.changedData;
		const columns = [{ Header: 'Attribute', accessor: 'attribute',headerStyle: { 'textAlign': 'left' }}, { Header: this.props.fromVersion.title, accessor: 'previous',headerStyle: { 'textAlign': 'left' }, Cell: this.renderFrom.bind(this)}, { Header: this.props.toVersion.title, accessor: 'current',headerStyle: { 'textAlign': 'left' }, Cell: this.renderTo.bind(this)}];
		//console.log({attributesData, columns});

		return (
		    (this.props.changedData && ((this.props.changedData.attributesData && this.props.changedData.attributesData.length>0) || (this.props.changedData.geometryData))) ?
		    <React.Fragment>
		    	{(this.props.changedData.attributesData && this.props.changedData.attributesData.length>0) ? 
		    	<React.Fragment>
	    		    <div className={'paPopupChangeType'}>The following attributes have changed:</div>
	        		{/* <ReactTable 
	                    className={'changeTable'}
	                    showPagination={false} 
	                    minRows={0}
	                    data={this.props.changedData.attributesData}
	                    columns={[{ Header: 'Attribute', accessor: 'attribute',headerStyle: { 'textAlign': 'left' }}, { Header: this.props.fromVersion.title, accessor: 'previous',headerStyle: { 'textAlign': 'left' }, Cell: this.renderFrom.bind(this)}, { Header: this.props.toVersion.title, accessor: 'current',headerStyle: { 'textAlign': 'left' }, Cell: this.renderTo.bind(this)}]}
	        		/>  */}
					<Table 
						data={attributesData}
	                    columns={columns}
	        		/>
	        	</React.Fragment> : null}
	        	{(this.props.changedData.geometryData) ? <div className={'geometryChangeDiv'}>{status && status.text}</div> : null}
        	</React.Fragment>
    		: null
		);
	}
}
function Table({ columns, data }) {
	// Use the state and functions returned from useTable to build your UI
	const {
	  getTableProps,
	  getTableBodyProps,
	  headerGroups,
	  rows,
	  prepareRow,
	} = useTable({
	  columns,
	  data,
	})
  
	// Render the UI for your table
	return (
	  <table className={'changeTable'} {...getTableProps()}>
		<thead>
		  {headerGroups.map(headerGroup => (
			<tr {...headerGroup.getHeaderGroupProps()}>
			  {headerGroup.headers.map(column => (
				<th {...column.getHeaderProps()}>{column.render('Header')}</th>
			  ))}
			</tr>
		  ))}
		</thead>
		<tbody {...getTableBodyProps()}>
		  {rows.map((row, i) => {
			prepareRow(row)
			return (
			  <tr {...row.getRowProps()}>
				{row.cells.map(cell => {
				  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
				})}
			  </tr>
			)
		  })}
		</tbody>
	  </table>
	)
  }

export default Changed;
